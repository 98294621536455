@import './theme'

body
  background-color: $brand-color !important

.loader_page
  width: 100vw
  height: 100vh
  display: flex
  align-items: center
  justify-content: center

.login-card
  border-radius: 32px
  padding: 32px 32px
  margin: 120px auto
  width: calc(100% - 120px)
  background-color: #FFFFFF
  height: auto

  .logo-card
    border: 8px solid $brand-color
    display: flex
    align-items: center
    justify-content: center
    position: absolute
    width: 300px
    height: 84px
    left: calc(50% - 150px)
    top: calc(0px - 44px)
    background-color: #FFFFFF
    border-radius: 32px

  .back-card
    border: 8px solid $brand-color
    width: 56px
    height: 56px
    display: flex
    align-items: center
    justify-content: center
    border-radius: 50%
    position: absolute
    left: -28px
    background-color: #FFFFFF
    cursor: pointer

    img
      width: 24px
      height: 24px

.flex
  display: flex

.ai-center
  align-items: center

.jc-between
  justify-content: space-between

.jc-center
  justify-content: center

.mt-16
  margin-top: 16px

//.ant-table-content
//  padding-bottom: 148px

.ant-table-pagination.ant-pagination
  background: aliceblue
  padding: 12px
  position: fixed
  width: calc(100% - 120px)
  bottom: 144px
  margin: 0 !important

.ant-table-wrapper
  width: 100%

.my-tabs .ant-table-pagination.ant-pagination
  margin-left: -16px !important

.card-footer
  position: fixed
  background-color: #FFFFFF
  bottom: 64px
  width: calc(100% - 120px)

.hover
  cursor: pointer

.space
  width: calc(100% - 16px)

.ant-table-cell
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis

@media screen and (max-width: 500px)
  .ant-card.card-footer.css-dev-only-do-not-override-42nv3w
    margin-left: 0 !important

  .login-card
    max-width: 320px
    padding: 8px

  .login-card .logo-card
    width: 250px
    left: calc(50% - 125px)

  .ant-table
    width: 100%

  .ant-table-thead
    tr
      th:nth-child(2), th:nth-child(3), th:nth-child(4), th:nth-child(5)
        display: none

      th:nth-child(1), th:nth-child(6)
        width: 50%

  .ant-table-tbody
    tr
      td:nth-child(2), td:nth-child(3), td:nth-child(4), td:nth-child(5)
        display: none

      td:nth-child(1), td:nth-child(6)
        width: 50%

  .title-header
    display: none

.card-body-custom
  margin-top: 1rem

.card-body-custom:first-child
  margin-top: 0


.ql-toolbar.ql-snow + .ql-container.ql-snow
  min-height: 20rem